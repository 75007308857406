<template>
  <div class="dialog">
    <div class="dialog-content">
      <h2>Before you can complete your registration</h2>
      <p>you must carefully read and accept the Terms & Conditions for Local Travel Agencies.</p>
      <h2>Terms & Conditions for Local Travel Agencies</h2>
      <span class="date">10. August 2021</span>
      <h3>Foreword</h3>
      <p>Travel Afrique operates an online platform (<b>"Platform"</b>), primarily at <a
          href="https://travelafrique.com">travelafrique.com</a>. Travelers (<b>"Travelers"</b>, <b>"Traveler"</b>,
        <b>"Customers"</b>, <b>"Customer"</b>,
        <b>"Members"</b>, <b>"Member"</b>) can book travel and other travel-related services (<b>"Services"</b>, <b>"Service"</b>)
        through Local
        Travel Agencies (<b>"Service Providers"</b>, <b>"Service Provider"</b>, <b>"Travel Agency"</b>, <b>"Travel
          Agencies"</b>, <b>"Local Agency"</b>,
        <b>"Local Agencies"</b>) via
        the Platform. When booking a Service, the contract is concluded between the Customer and the Service Provider
        mediated by Travel Afrique.</p>
      <h3>1. Scope of application</h3>
      <p>The Terms & Conditions for Local Travel Agencies (<b>“Terms”</b>, <b>"Terms and Conditions"</b>) apply to all
        legal
        transactions concluded
        via the platform. Travel Afrique reserves the right to change these Terms at any given time. The version of
        these Terms which is valid at the time of registration and which cannot be changed unilaterally shall be
        decisive
        in each case. Conflicting or deviating conditions of the Local Agency will not be recognized.</p>
      <p>Changes to these Terms and Conditions will be communicated to already in the Platform registered Local Agencies
        by email at least 4 weeks before the change.</p>
      <h3>2. Providing Services on Travel Afrique</h3>
      <h4>2.1 Local Travel Agencies</h4>
      <p>As a Local Travel Agency, Travel Afrique authorizes you to use the Travel Afrique Platform to offer travel
        services to the African continent to our community of Travelers - and earn some extra money. You receive travel
        requests and can easily create a suitable quote through our platform. </p>
      <h4>2.2 Contracting with Travelers</h4>
      <p>When you confirm a booking request or receive a booking confirmation through the Travel Afrique Platform, you
        enter into a contract directly with the Traveler and are responsible for providing the Traveler Service on the
        terms and at the price you specified in your quote. You are also responsible for paying any applicable fees such
        as
        Travel Afrique's service fee (and applicable taxes in your country of operation) for each booking. Travel
        Afrique will deduct these amounts
        from your payouts unless we agree with you to do otherwise. Any terms or conditions that you include in any
        supplemental guest contract must: (i) be consistent with these Terms, our Additional Legal Terms, our
        policies and the information provided in your quote, and (ii) be clearly stated in
        your quote description.</p>
      <h4>1.3 Independence of Local Agencies</h4>
      <p>Although we informally refer to the Local Travel Agencies on our Platform as "Local Partners," the formal and
        legal relationship of the Local Travel Agencies to Travel Afrique is that of an independent organization and not
        that of an employee, agent, joint venture or partner, except that Travel Afrique acts as a collection agent.
        Travel Afrique does not direct or control your travel Services and you understand that it is in your sole
        discretion to provide travel Services at your rates and cancellation terms.
      </p>
      <p>
        Furthermore, you hereby certify that
        you are a legally registered company in your country or countries of operation and that you have insurance
        coverage, as well as for the vehicles used to transport our respective clients.
      </p>
      <h3>2. Managing your trip requests and quotes</h3>
      <h4>2.1 Trip requests and quotes</h4>
      <p>The Travel Afrique platform provides tools that make it easy for you to receive travel requests from travelers
        and create quotes for them. Your quotes must contain complete and accurate information about your service. This
        includes your price, information about services included and excluded in the price, itinerary, accommodations
        and
        cancellation period. You are responsible for your actions, omissions, and accepted travel requests, as well as
        for
        ensuring that your information and content (such as profile photos, phone number) are always up-to-date and
        accurate.</p>
      <h4>2.2 Payments and transactions</h4>
      <p>Payment and transaction methods may involve the use of third-party payment service providers. These payment
        service providers may
        charge you additional fees for processing payments in connection with the Payment Services, and Travel Afrique
        is
        not responsible for any such fees and disclaims any liability in this regard. Travel Afrique is not responsible
        for any loss suffered by you as a result of incorrect payment method information provided by you.</p>
      <h4>2.4 Legal obligation</h4>
      <p>You are responsible for understanding and complying with all laws, rules, regulations and treaties with the
        country or countries in which you operate and with third parties that apply to your offerings and Services.
        Consult the
        regulations in effect in your country to learn what rules apply to the Services you plan to provide. The
        information we provide about legal requirements is for informational purposes only, and you should independently
        ascertain your obligations. You are responsible for handling and using personal information about Travelers and
        others in accordance with applicable privacy laws and these Terms. If you have any questions about the
        application of local laws, you should always seek legal advice.</p>
      <h4>2.5 Minimum Quality Standards, Local Agency Responsibilities</h4>
      <p>You are responsible for your own acts and omissions, as well as the acts and omissions of those you allow to
        participate in the provision of your Services.</p>
      <h5>2.5.1 Minimum quality standards</h5>
      <p>Furthermore, by agreeing to these terms, you agree to work responsibly, reliably and to the best of your
        knowledge and belief at all times. This means:</p>
      <ul>
        <li>If available, maintain your fleet of vehicles in perfect condition.</li>
        <li>Use or hire professional and trained guides only.</li>
        <li>Provide well-maintained sanitary facilities.</li>
        <li>Create a quote for a travel request only if you are sure you have the
          resources and conditions to provide the services offered to the Traveler in the quote at the offered
          conditions.
        </li>
        <li>If you are unable to provide a quote for a travel request, you must notify us in less than 8 hours
          via email or phone.
        </li>
        <li>For each trip request received, a quote must be provided within 72 hours of receipt.</li>
      </ul>
      <h5>2.5.3 Agency Responsibilities</h5>
      <p>You are also responsible for ensuring that the services you provide on the Travel
        Afrique Platform are accessible, reasonably and accurately described and safe and do not present the Traveler
        with
        Travel Problems, as set forth in the Terms (section 3). During the period in which the Traveler is using the
        booked
        Service(s), the Local Agency should be available or provide a third party to attempt in good faith to resolve
        any Travel Problems or other issues that may arise.</p>
      <p>You also should provide a clean, safe and sanitary environment for the Travelers.
        Inform the Travelers and Travel Afrique in advance about following:</p>
      <ul>
        <li>Various entry documents or vaccines required for your destination(s).</li>
        <li>Any social conditions, political, natural and concerning situations, or areas to be avoided in your
          country.
        </li>
      </ul>
      <h3>3. Cancellation, Travel Problem and booking modifications</h3>
      <h4>3.1 Travel Problems</h4>
      <p>A "Travel Problem" occurs when the Local Agency (i) cancels a booking before the scheduled start date or
        shortly after the start date of the booked service.
        If only particular parts of the trip are canceled, provided that the trip does not endanger the health or life
        of
        the Traveler or Travelers due to unforeseeable circumstances mentioned above, the Local Agency is obliged to
        offer an equivalent substitute Service at no extra cost. If this is not possible, the amount paid for the
        cancelled Service
        shall be refunded to the Traveler, however, the remaining part of the booked Service or Services will not be
        cancelled</p>
      <h4>3.2 Cancellation by Local Agency</h4>
      <p>You may cancel a booking at any time prior to the Traveler's departure if it is not possible for you to provide
        the booked Service due to circumstances and events beyond your control (e.g. terrorism, natural disasters,
        pandemics, political instability or other unforeseen circumstances).</p>
      <p>However, any cancellation must be under applicable law of the country you are operating in and confirmed with
        Travel Afrique and Travel Afrique must be informed immediately
        in writing via email or by telephone as soon as you become aware of the inability to perform the Service or
        Services.</p>
      <p>If Travel Afrique concludes that the reason for the cancellation is your responsibility and within your
        control, Travel Afrique may charge the Local Agency expense fees for the effort expended to rebook the trip with
        another Local Agency and to provide service and support to the affected Travelers.</p>
      <h5>3.2.1 Conditions for making a cancellation</h5>
      <p>To submit a valid cancellation for a Travel Problem and receive the payment with respect to the booking, you
        must
        meet each of the following conditions:
      <ul>
        <li>You must report the Travel Problem to us in writing (<a href="mailto:contact@travelafrique.com">email us</a>)
          within 3 hours of discovering the existence
          of the Travel Problem;
        </li>
        <li>You must respond to all inquiries from us for additional information or assistance regarding the Travel
          Problem within the time period defined by Travel Afrique;
        </li>
        <li>You must inform us about the circumstances of the Travel Problem;</li>
        <li>You must not have caused the Travel Problem, either directly or indirectly (by your act, neglect, or wilful
          misconduct);
        </li>
      </ul>
      <p>Unless Travel Afrique otherwise determines that the Travel Problem cannot be resolved, you must use reasonable
        efforts to attempt to resolve the circumstances of the Travel Problem with the Traveler or Travelers.</p>
      <p>In general Travel Afrique is not liable for any incidental expenses a Local Agency incurs as a result of any
        cancellation of a booking.</p>
      <h4>3.3 Cancellation by Traveler</h4>
      <p>If a Traveler cancels his or her booking, cancellation fees will apply according to the Local Agency's applied
        Cancellation Terms in the quote.</p>
      <h4>3.4 Booking modifications</h4>
      <p>Travelers may at any time request changes to the quotes you provide and you are obliged to provide a new quote
        within 72 hours
        according to the needs and requirements of the Travelers. Changes to existing quotes and bookings that were not
        requested by the Traveler, due to an error on your part, are subject to additional charges for the services and
        efforts of Travel Afrique on behalf of the Travelers.</p>
      <h3>4. Competition clause</h3>
      <p>If the same travel request is made by a customer on another competing platform that you also work with, you
        should give priority to the request from the Travel Afrique Platform if the request came through Travel Afrique
        first.</p>
      <p>Do not encourage Travelers to create third party accounts, submit reviews, provide their contact information,
        or commit other acts outside of the Travel Afrique Platform. In particular, refrain from any attempt to convince
        Travelers to book trips with you outside of the Travel Afrique platform. When hosting Travelers who have booked
        through Travel Afrique, you should not self-promote or market to the customer for other organizations and
        purposes. The Traveler is booking through Travel Afrique, and therefore you should make every effort to market
        their experience as a Travel Afrique experience. Violations of this rule will be
        punished by permanent exclusion from the Platform without exception.</p>
      <h3>5. Travel Afrique Services</h3>
      <p>Travel Afrique is interested in the financial success of the local agencies. Therefore we operate the following
        services to achieve this:</p>
      <ul>
        <li>
          Regular user survey to identify current problems and needs.
        </li>
        <li>Continuous development and improvement of the Travel Afrique platform.</li>
        <li>Marketing to attract more traffic to the platform.</li>
        <li>Customer service and support for Local Agencies and Travelers.</li>
        <li>Qualification of leads.</li>
      </ul>
      <h3>6. Exclusion of liability</h3>
      <p>Travel Afrique operates the Platform to the best of its ability and is committed to keeping the platform
        security compliant and in line with the latest best practices and technologies.</p>
      <p>Although we do our best to keep the system functional at all times, as is the nature of web services, we cannot
        guarantee 100% reliability against failures or errors of an unexpected nature. Therefore, Travel Afrique is not
        liable for
        any damages or loss of revenue resulting from system malfunctions, failures, and any other events that are not
        our responsibility and are not the result of Travel Afrique's negligence and fault.</p>
      <h3>7. Governing Law</h3>
      <p>These Terms shall be governed by the laws of Zurich, Switzerland, without respect to its conflict of law
        provisions. Our failure to enforce any right or provision of these Terms will not be deemed a waiver of such
        rights. If any provision of these Terms is held invalid or unenforceable by a court, the remaining provisions of
        these Terms will remain in full force and effect. These Terms constitute the entire agreement between us with
        respect to our Service and supersede all prior agreements between us with respect to the Service.</p>
      <Checkbox v-model="readTermsConfirmation" :showErrMsg="isInErrorMode"
                label="I confirm that I have read, understood and agreed to the Terms & Conditions for Local Travel Agencies."/>
      <ButtonPrimary class="px-3 w-full" text="Accept terms" @click.native.prevent="acceptTerms"/>
    </div>
  </div>
</template>

<script>
import ButtonPrimary from "@/components/Buttons/ButtonPrimary";
import Checkbox from "@/components/Inputs/Checkbox";

export default {
  name: "Terms",
  components: {Checkbox, ButtonPrimary},
  data() {
    return {
      readTermsConfirmation: false,
      isInErrorMode: false,
    }
  },
  methods: {
    acceptTerms() {
      if (this.readTermsConfirmation) {
        this.$emit('acceptedTerms', this.readTermsConfirmation)
      } else {
        this.isInErrorMode = true;
      }

    }
  },

  watch: {
    readTermsConfirmation(newVal) {
      if (newVal === true) {
        this.isInErrorMode = false
      }
    }
  }

}
</script>

<style scoped>
.dialog {
  @apply rounded-md bg-white h-80vh overflow-hidden max-w-3xl w-full ;
}

.dialog-content {
  @apply h-full w-full overflow-scroll px-16 pt-12 pb-16;
}

h2 {
  @apply font-semibold text-2xl;
  @apply mt-10 mb-2;
}

h3 {
  @apply font-semibold text-xl mb-1.5 mt-8;
}

h4 {
  @apply font-semibold text-lg mb-1 mt-6;
}

h5 {
  @apply font-semibold text-base mb-0.5 mt-4;
}

ul {
  @apply list-disc pl-6;
}

li {
  @apply py-1;
}

p {
  @apply mb-1;
}

b {
  @apply font-semibold;
}
</style>