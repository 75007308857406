<template>
  <div class="page">
    <!--  we need to get the user terms  and also capture the click event of the accept terms component-->

    <Terms
      class="terms"
      v-if="showTerms"
      @acceptedTerms="acceptTermsAndConditions"
    />
    <BackgroundLayer v-if="showTerms" class="terms-bg" />
    <aside class="first-half">
      <div>
        <figure class="logo">
          <img src="@/assets/img/logo.svg" alt="Logo" />
        </figure>
        <form class="login-form">
          <h1 class="title">Complete your registration</h1>

          <template v-if="showError">
            <p class="error-msg">
              {{ errorMsg }}
            </p>
          </template>
          <div v-if="isLoading">
            <Loading />
          </div>

          <div v-else>
            <InputText
              label="Password"
              placeholder="Password"
              type="password"
              name="password"
              v-model="userCredentials.password"
              class="mb-6"
            />

            <InputText
              label="Retype Password"
              placeholder="Your password"
              type="password"
              name="password"
              v-model="userCredentials.passwordConfirmation"
            />
            <Checkbox label="Stay logged in" />

            <div class="block ml-auto py-2 w-32" v-if="isLoading">
              <Loading
                margin="my-0 mr-0"
                label="Logging in"
                :show-label="true"
              />
            </div>

            <ButtonPrimary
              v-else
              type="submit"
              text="Create account"
              @click.native.prevent="completeRegistration"
              class="btn-login"
            />
          </div>
        </form>
        <footer>
          <div class="footer-container">
            <span class="credits">
              Made with ♥ remotely from Ghana, Nigeria, Mauritius & Switzerland.
            </span>
            <span class="credits copyrights">
              © {{ new Date().getFullYear() }} Travel Afrique, LLC. All rights
              reserved.
            </span>
          </div>
        </footer>
      </div>
    </aside>
    <aside class="second-half">
      <img
        class="bg-img"
        :src="require(`@/assets/img/backgrounds/${imgPath}`)"
        alt="Background Image"
      />
    </aside>
  </div>
</template>
<script>
import ButtonPrimary from "@/components/Buttons/ButtonPrimary";
import Checkbox from "@/components/Inputs/Checkbox";
import InputText from "@/components/Inputs/InputText";
import { mapActions } from "vuex";
import Loading from "@/components/Preloaders/Loading";
import Terms from "@/components/Dialogs/Terms";
import BackgroundLayer from "@/components/Layout/BackgroundLayer";

export default {
  name: "CompleteRegistration",
  components: {
    Loading,
    ButtonPrimary,
    Checkbox,
    InputText,
    Terms,
    BackgroundLayer,
  },
  data() {
    return {
      imgPath: "1.jpeg",
      isLoading: false,
      showError: false,
      errorMsg: "",
      isValid: false,
      showTerms: true,
      userCredentials: {
        agreementAccepted: false,
        passwordConfirmation: "",
        password: "",
      },
    };
  },

  mounted() {
    //validate token
    this.isLoading = true;
    this.checkVerifyToken(this.$route.query.token)
      .then(({ data }) => {
        let userData = data.userData;
        this.userCredentials.id = userData.id;
        this.userCredentials.name = userData.name;
        this.userCredentials.email = userData.email;
        this.userCredentials.partnerId = userData.partnerId;
        this.userCredentials.urlToken = userData.urlToken;
        this.isValid = true;
        this.isLoading = false;
      })
      .catch((error) => {
        console.log(error);

        this.isValid = false;
        this.isLoading = false;
        this.$router.replace("/login");
        this.$notify({
          type: "error",
          title: `${error.message}`,
          text: `${error.response.data.message}`,
        });
      });
  },
  methods: {
    ...mapActions({
      checkVerifyToken: "auth/checkVerifyToken",
      registerPartner: "auth/registerPartner",
    }),

    async completeRegistration() {
      if (!this.userCredentials.agreementAccepted) {
        this.showTerms = true;
        return;
      }

      if (
        this.userCredentials.password !==
        this.userCredentials.passwordConfirmation
      ) {
        this.$notify({
          type: "error",
          title: `Error`,
          text: `The passwords must match`,
        });
        return;
      }

      if (
        this.userCredentials.password.length < 6 ||
        this.userCredentials.passwordConfirmation.length < 6
      ) {
        this.$notify({
          type: "error",
          title: `Error`,
          text: `The password must be a minimum of 6 characters`,
        });
        return;
      }

      this.isLoading = true;
      try {
        let regPartner = await this.registerPartner(this.userCredentials);

        if (regPartner.status === 200) {
          this.isLoading = false;
          this.$notify({
            type: "success",
            title: `Successful`,
            text: `Registration Successful`,
          });
          await this.$router.push({ name: "Requests" });
        } else {
          this.showError = true;
          this.isLoading = false;
          setTimeout(() => {
            this.showError = false;
          }, 10000);
        }
      } catch (e) {
        this.showError = true;
        this.isLoading = false;
        //hide the error after a minute
        setTimeout(() => {
          this.showError = false;
        }, 10000);
      }
    },

    getRandomImg(totalImg) {
      let random = Math.floor(Math.random() * totalImg);
      this.imgPath = random + ".jpeg";
    },

    acceptTermsAndConditions(value) {
      this.userCredentials.agreementAccepted = value;
      this.showTerms = false;
    },
  },
  beforeMount() {
    this.getRandomImg(45);
  },
};
</script>
<style scoped>
.page {
  @apply w-100vw h-100vh flex sm:flex-wrap bg-grey-lighter;
}

.first-half {
  @apply sm:h-full w-full md:w-5/8 px-4 py-8 sm:p-8;
  @apply flex justify-center items-center;
}

.second-half {
  @apply hidden md:block h-full w-3/8 p-8 ml-auto relative;
}

.bg-img {
  @apply h-full w-full object-cover absolute top-0 left-0;
}

.logo {
  @apply h-13 relative my-9;
}

.logo img {
  @apply absolute object-cover sm:left-0 top-0 h-full left-1/2 -translate-x-1/2 transform sm:transform-none sm:translate-x-0;
  @apply relative left-1/2 transform -translate-x-1/2;
}

.login-form {
  @apply relative left-1/2 transform -translate-x-1/2 bg-white;
  @apply w-full max-w-sm sm:max-w-lg px-8 py-12 sm:px-16 sm:py-16;
  @apply shadow-md border border-grey-base rounded-md mb-4;
}

.title {
  @apply font-sans text-black-base font-semibold text-xl sm:text-2xl mb-5;
}

.btn-login {
  @apply px-8 block ml-auto mt-6 w-full;
}

.error-msg {
  @apply font-sans text-red-base text-sm mb-5;
}

footer {
  @apply relative left-1/2 transform -translate-x-1/2;
  @apply max-w-sm sm:max-w-lg pb-12;
}

.footer-container {
  @apply flex justify-between flex-wrap;
}

.credits {
  @apply text-black-lighter text-xs w-full md:text-sm mb-2;
}

.copyrights {
  @apply text-left;
}

.terms {
  @apply fixed z-50 left-1/2 transform -translate-x-1/2  top-1/2 -translate-y-1/2;
}

.terms-bg {
  @apply fixed h-screen top-0 left-0 w-screen z-40;
}
</style>
